/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&family=Roboto:wght@300;400;500;700&display=swap');

body * {
  font-family: 'Poppins', sans-serif !important;
}

body {
  margin: 0;
  line-height: normal;
}


.editbox {
  padding: 10px;
}
.card {
  background: white;
    padding: 15px;
    margin: 20px;
}

.card span {
  font-size: 13px;
    margin-bottom: 2px;
    font-weight: 500;
}
   
.loader-block {
  box-shadow: 1px 1px 12px 0px rgba(14, 28, 160, 0.75);
-webkit-box-shadow: 1px 1px 12px 0px rgba(21, 28, 128, 0.75);
-moz-box-shadow: 1px 1px 12px 0px rgba(11, 1, 150, 0.75);
background: white;
padding: 20px;
border-radius: 10px;
      }

      .loader-parent {
        height: 100%;
        width: 100%;
        max-width: 500px;
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 9999;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.103);
      }

      .loader {
        width: 50px;
        padding: 8px;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #7066e0;
        --_m: 
          conic-gradient(#0000 10%,#000),
          linear-gradient(#000 0 0) content-box;
        -webkit-mask: var(--_m);
                mask: var(--_m);
        -webkit-mask-composite: source-out;
                mask-composite: subtract;
        animation: l3 1s infinite linear;
      }
      @keyframes l3 {to{transform: rotate(1turn)}}

.logoIcon {
  position: relative;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  object-fit: cover;
  padding: 4px;
  border-radius: 100%;
  box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.45);
  -webkit-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.45);
  -moz-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.45);
  z-index: 99;
  background: white;
  align-self: center;
}

.loginBackDesign {
  position: absolute;
  background: #018d96;
  width: 100%;
  max-width: 500px;
  height: 35vh;
  border-radius: 0px 0px 23px 23px;
  color: white;
  text-align: center;
  padding: 20px;
}

.animated {
  height: 100vh;
  z-index: 10;
}

a {
  text-decoration: none !important;
  color: unset !important;
}

.SplashDiv {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.backdraw {
  position: absolute;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background: #0000008f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar {
  background: #018d96;
    background: -webkit-linear-gradient(to bottom, #018d96, #042d55);
    background: linear-gradient(to bottom, #018d96, #018d96);
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar div {
  display: flex;
}

.toolbar img {
  height: 32px;
}

.toolbar .wallet-img {
  height: 26px;
}
.toolbar .wallet-text {
  font-size: 18px !important;
  margin-left: 5px;
}
.toolbar span {
  font-size: 20px;
  margin-left: 10px;
  color: white;
  font-weight: 600;
}

.nobackdraw {
  display: none !important;
}

.backdraw img {
  border-radius: 10px;
}

.RootWidth {
  width: 500px;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.RootView {
  height: 100vh;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.button {
  cursor: pointer;
  border: 0;
  color: white;
  padding: 10px;
  background-color: var(--button-background);
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: center;
}

.button b {
  position: relative;
  font-size: var(--font-size-xl);
  display: inline-block;
  font-family: var(--font-inika);
  color: var(--button-textcolor);
  text-align: left;
}

.button a {
  width: 100%;
}

.edittext {
  border-radius: 5px;
  border: solid 1px var(--color-accent) !important;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 11px !important;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.carousel-item img {
  height: 200px;
}

.edittext-disable {
  background: #dddddd;
    border: solid 1px #dddddd !important;
  border-radius: 5px;
  border: solid 1px var(--color-accent) !important;
  background-color: var(--color-white);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 11px !important;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.depositBox {
  padding:0px 0px 0px 11px !important
}

.think-border {
  border: solid 3px var(--color-accent) !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.think-border2 {
  border: solid 3px var(--color-accent) !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.think-border3 {
  border: solid 3px var(--color-accent) !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.think-border3 span {
  font-weight: 700;
}

.think-border2 span {
  font-weight: 700;
}

.think-border span {
  font-weight: 700;
}

.edittext:active {
  animation: 1s ease 0s infinite normal none shadow-drop-bottom;
  opacity: 1;
}

.pageHeadings {
  position: relative;
  font-size: var(--font-size-2xl);
  font-family: var(--font-inika);
  color: var(--color-font);
  text-align: center;
  display: inline-block;
  font-weight: 600;
  width: 100%;
  height: 27px;
  flex-shrink: 0;
}

.authBack {
  background-color: white;
}

.borderdiv {
  border: solid 2px #0000004d;
  border-radius: 10px;
}

iframe {
  width: 100%;
  height: 90vh;
}

.suggest {
  list-style-type: none;
  padding-left: 0;
  -webkit-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 23px -5px rgba(0,0,0,0.75);
  position: absolute;
  background: white;
  width: 75px;
  text-align: center;
}

.suggest li {
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  padding: 5px 5px 5px 5px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.17);
  font-weight: 600;
}

.nav-top-head {
  justify-content: space-between;
  background-color: var(--button-background);
  padding: 25px 20px;
}

.nav-top-head img {
  height: 54px;
}

.nav-top-child1 {
  display: flex;
  flex-direction: column;
}
.nav-top-child1 span {
  color: white;
  font-size: 16px;
}

.popup {
  z-index: 10000;
  position: fixed;
  width: 100%;
  width: 100%;
  max-width: 500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #000000a8;
}

.popup .main {
  max-height: 80vh;
}


.chartButton {
  background: #2b7684;
  color: white;
  border-radius: 7px;
  text-align: center;
}

.wallet-blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: #018d96;
  border-radius: 18px;
  color: white;
  font-weight: 600;
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.wallet-block-main {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  padding: 5px;
  justify-content: space-around;
}

.wallet-blocks img {
  height: 57px;
  width: 57px;
  background: #fff;
  padding: 8px;
  border-radius: 39px;
  margin-bottom: 5px;
}

.popup .cancel {
  width: 30px;
  height: 30px;
}


.marquee {
  height: 35px;
  overflow: hidden;
  position: relative;
  background: var(--button-background);
  color: var(--button-textcolor);
  background: #018d96;
  background: -webkit-linear-gradient(to bottom, #018d96, #018d96);
  background: linear-gradient(to bottom, #018d96, #018d96);
}

.marquee span {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 30px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}



:root {
  /* fonts */
  --font-inika: Inika;

  /* font sizes */
  --font-size-xs: 11px;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-lg: 15px;
  --font-size-xl: 16px;
  --font-size-2xl: 20px;
  --font-size-3xl: 24px;

  /* Colors */
  --color-font: #000;
  --color-accent: #018d96;
  --button-background: #018d96;
  --button-textcolor: #fff;


  --color-white: #fff;
  --color-pink-100: #ffcbcb;
  --color-pink-200: #fcb6b6;
  --color-pink-300: #ff8585;
  --color-red: #ff8585;
  --color-gray: #1f1f1f;
  --color-black: #000;
  --color-gray-100: #ffeded;
  --color-gray-200: #f0f0f0;
  --color-gray-300: #cbe0ff;
  --color-gray-400: #d1d1d1;
  --color-gray-500: #4b5a91;
  --color-gray-600: #4f4d4d;
  --color-gray-700: rgba(0, 0, 0, 0.83);
  --color-gray-800: rgba(0, 0, 0, 0.22);
  --color-gray-900: rgba(0, 0, 0, 0.22);
  --color-gray-1000: rgba(0, 0, 0, 0.83);
  --color-gray-1100: rgba(0, 0, 0, 0.5);
  --color-blue-100: #0b32ff;
  --color-blue-200: rgba(0, 26, 255, 1);
  --color-blue-300: rgba(0, 26, 255, 0.5);
  --color-green-100: rgba(0, 160, 16, 1);

  /* Gaps */
  --gap-0: 0px;
  --gap-xs: 1px;
  --gap-sm: 5px;
  --gap-md: 10px;
  --gap-lg: 12px;
  --gap-xl: 24px;

  /* Paddings */
  --padding-4xs: 5px;
  --padding-3xs: 6px;
  --padding-2xs: 7px;
  --padding-xs: 8px;
  --padding-sm: 9px;
  --padding-md: 10px;
  --padding-lg: 17px;
  --padding-xl: 20px;
  --padding-2xl: 28px;
  --padding-3xl: 34px;
  --padding-4xl: 34px;

  /* border radiuses */
  --br-2xs: 5px;
  --br-xs: 6px;
  --br-sm: 7px;
  --br-md: 9px;
  --br-lg: 12px;
  --br-xl: 22px;
  --br-2xl: 22px;
}

.project-tab {
  padding: 10%;
  margin-top: -8%;
}
.project-tab #tabs{
  background: #007b5e;
  color: #eee;
}
.project-tab #tabs h6.section-title{
  color: #eee;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #0062cc;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  font-size: 16px;
  font-weight: bold;
  background: rgb(47,140,149) !important;
  background: linear-gradient(0deg, rgb(48 141 150) 0%, rgb(47 140 149) 50%, rgb(106 222 233) 100%) !important;
  color: white !important;
}


.histMia{
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #00000052;
}

.histMiaSub{
  margin-right: 15px;
  margin-top: 15px;
}

.histMiaSub img {
  height: 24px;
  margin-right: 5px;
}

.histMiaSub span {
  font-size: 14px;
  font-weight: 600;
  color: #05bf05;
}
.new_upi_apps {
  display: grid !important;
  grid-template-columns: 47% 47% !important;
  grid-row-gap: 15px !important;
}

.new_upi_apps div {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.gameBoxMina {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.gameBox {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  
  box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
  -webkit-box-shadow: -1px 1px 10px -7px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: -1px 1px 16px -7px rgba(0, 0, 0, 0.75) !important;
}
.paymentStatusDiv {
  flex-direction: column !important;
}

.paymentStatusDiv .phead {
  font-weight: 700;
  font-size: 18px;
}
.paymentStatusDiv .psub {
  font-size: 13px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}


.qr_image {
  height: 200px;
  margin-bottom: 15px;
}


.main-grid {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.main-grid div {
  display: flex;
  flex-direction: column;
  border: solid 1px;
  width: 100%;
}

.main-grid div span {
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
}

.project-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  color: #0062cc;
  font-size: 16px;
  font-weight: 600;
}
.project-tab .nav-link:hover {
  border: none;
}
.project-tab thead{
  background: #f3f3f3;
  color: #333;
}
.project-tab a{
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

.jantri-parent {
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.jantri-box {
  width: 30% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px;
}

.jantri-box2 {
  width: 10% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.loginDiv {
    max-width: 500px;
}

.jantri-box2 span {
  font-size: 14px;
    background: #018d96;
    width: 100%;
    color: white;
    text-align: center;
    padding: 2px;
}

.jantri-box2 .edittext {
  font-size: 13px;
  min-height: 40px;
  padding: 0 !important;
  border-radius: 0 !important;
  text-align: center;
  font-weight: 500;
}

.jantri-box input {
  text-align: center;
}

.place-parent {
  position: fixed;
  bottom: 10px;
  max-width: 475px;
  width: 94%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.crossingParent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.crossing-box {
  width: 40% !important;
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  align-items: center;
}

.crossing-bets-parent {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.crossing-bets-child {
  pointer-events: none;
  width: 20% !important;
  text-align: center;
  margin: 0px 5px;
}

.nav-link {
  padding: 9px 10px !important;
  font-size: 15px !important;
}

.nav-tabs {
  margin-bottom: 15px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    margin-top: 6px !important;
}

.open-box {
  width: 45% !important;
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  align-items: center;
}

.splash_logo {
  position: relative;
  width: 242px;
  height: 245px;
  flex-shrink: 0;
  object-fit: cover;
  /* padding: 10px; */
  border-radius: 23px;
  box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.45);
  -webkit-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.85);
  -moz-box-shadow: 1px 1px 12px 0px rgba(1,141,150,0.45);
  z-index: 99;
  background: white;
  align-self: center;
}

.open-box-child {
  display: flex;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
  padding: 5px;
  border: solid 1px var(--color-accent) !important;
  border-top: solid 1px px var(--color-accent) !important;
}

.open-box-child span {
  font-size: 12px;
}

.error-msg {
  color: red;
    font-size: 11px;
}

.refer-parent {
  display: flex;
  padding: 10px;
  --angle: 0deg;
	border: 5px solid;
	border-image: conic-gradient(from var(--angle), #2f8892, #1b3552,  #82e8f1, #2f8892) 1;
	animation: 3s rotate linear infinite;
  background: #2d808c;
  color: white;
  font-weight: 600;
}


@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}


.refer-text {
  display: flex;
  align-items: center;
  text-align: center;
}

.shareby {
  background: #35a74e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
  padding: 5px;
  border-radius: 8px;
}

.shareby img {
  padding: 5px;
  height: 35px
}

span.horizontal-shake {
  display: inline-block;
  animation: horizontal-shaking 2s infinite;
  margin-right: 10px;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}

.refer-p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.game-date {
  font-size: 12px;
    font-weight: 500;
    border-top: solid 1px #00000038;
    text-align: center;
    width: 100%;
    padding: 2px;
}

.game-sections {
  width: 30%;
    display: flex;
    flex-direction: column !important;
}

.game-sections .title {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}

.game-sections .data {
  text-align: center;
  text-transform: uppercase;
}